<script>
    import tupan from '../assets/tupan.png'
    import bg from '../assets/bg-tupan-1-ed023ece-1920w.webp'
</script>

<div class="header">
    <div class="logo">
        <img src={tupan} alt="">
        <div><span>TVOTE</span></div>
    </div>
</div>

<div class="coming-soon">
    <div>
        COMING <span>SOON</span>
    </div>
</div>

<div class="bg"/>

<style lang="scss">
  .header {
        height: 80px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background-color: white;
    padding-left: 60px;
    z-index: 3;
  }
    .logo {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-family: 'Vito', serif;
        font-weight: bold;
          color: #436a1a;
          height: 45px;
          font-size: 27px;
      div {
        transform: translateY(4px) translateX(5px);
      }
      img {
        height: 45px;
      }
    }
    .coming-soon {
      text-align: center;
        font-family: 'Vito', serif;
        font-weight: bold;
        color: white;
        font-size: 60px;
      height: 500px;
      width: 100%;
      display: flex;
      justify-content: center;
      z-index: 3;
      align-items: center;
      span {
        color: #95c502;
      }
    }
    .bg {
      isolation: isolate;
      position: absolute;
      inset: 0 0 0 0;
      z-index: -1;
      filter: brightness(0.5);
      backdrop-filter: brightness(0.4);
      background: url("/bg.webp");
      background-size: cover;
    }

</style>